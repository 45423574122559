/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import UserAPI from '../api/UserAPI';
import paths from '../consts/paths';
import { AuthProviderContext } from '../contexts/AuthContext';
import JWTManager from '../utils/JWTManager';
import WindowUtils from '../utils/WindowUtils';

export default function AuthProvider({ children }) {
  const authToken = JWTManager.getToken();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isAuthenticated, setIsAuthenticated] = useState(!!authToken);

  const setJwtToken = (token, callback) => {
    JWTManager.setToken(token).then(setIsAuthenticated(true), callback());
  };

  // eslint-disable-next-line consistent-return
  const signIn = async (
    email,
    password,
    setFailedSignIn,
    setErrorMsg,
    setSignInLoading,
    callback
  ) => {
    const config = {
      data: {
        schools_portal_user: {
          email,
          password
        }
      }
    };
    try {
      const response = await UserAPI.signIn(config);
      const { authorization } = response.headers;
      setFailedSignIn(false);
      setSignInLoading(false);
      setErrorMsg(null);
      setJwtToken(authorization, callback);
      enqueueSnackbar('Welcome back to Schools Portal!', { variant: 'success' });
    } catch (err) {
      setFailedSignIn(true);
      setSignInLoading(false);
      // For a 401 we'll show a standard message
      // But if it's not a 401, we want to see exactly what went wrong

      if (err.request?.status !== 401) {
        const error = err.response?.data?.error || err.message;
        setErrorMsg(error);
      }
    }
  };

  const signInAfterSetPassword = async (email, password) => {
    const config = {
      data: {
        schools_portal_user: {
          email,
          password
        }
      }
    };
    try {
      const response = await UserAPI.signIn(config);
      const { authorization } = response.headers;
      setJwtToken(authorization, () => navigate(paths.ROOT, { replace: true }));
      enqueueSnackbar('Password set! Welcome to Schools Portal!', { variant: 'success' });
    } catch (_err) {
      navigate(paths.LOG_IN, { replace: true });
    }
  };

  // eslint-disable-next-line consistent-return
  const signUp = async (
    title,
    firstName,
    lastName,
    email,
    role,
    school,
    optedInForMarketing,
    setSignUpLoading,
    setFailedSignUp,
    setErrorMsg
  ) => {
    const config = {
      data: {
        registration: {
          email,
          title,
          first_name: firstName,
          last_name: lastName,
          job_title: role,
          urn: school,
          opted_in_for_marketing: optedInForMarketing
        }
      }
    };
    try {
      const response = await UserAPI.signUp(config);
      setFailedSignUp(false);
      setErrorMsg(null);
      setSignUpLoading(false);
      const currentLocation = WindowUtils.currentPath();
      if (currentLocation === paths.RESOURCE_HUB_SIGN_UP) {
        navigate(paths.RESOURCE_HUB_SIGN_UP_CONFIRMATION, {
          state: { firstName, email, registrationResponse: response.data }
        });
      } else {
        navigate(paths.SIGN_UP_CONFIRMATION, {
          state: { firstName, email, registrationResponse: response.data }
        });
      }
    } catch (err) {
      setFailedSignUp(true);
      const error = err.response?.data?.error || err.message;
      setErrorMsg(error);
      setSignUpLoading(false);
    }
  };

  const signOut = (callback) => {
    UserAPI.signOut().then(() => {
      JWTManager.removeToken();
      setIsAuthenticated(false);
      localStorage.clear();
      callback();
      enqueueSnackbar('Signed out successfully. See you soon!', { variant: 'success' });
    });
  };

  const value = { isAuthenticated, signIn, signInAfterSetPassword, signOut, signUp };

  return <AuthProviderContext properties={value}>{children}</AuthProviderContext>;
}
