const paths = Object.freeze({
  ROOT: '/',

  // AUTH
  LOG_IN: '/login',
  SIGN_UP: '/signup',
  SIGN_UP_CONFIRMATION: '/signup-confirmation',
  REQUEST_PASSWORD_RESET: '/request-password-reset',
  SET_PASSWORD: '/set-password',
  UNLOCK_ACCOUNT: '/unlock-account',

  // LOGGED IN PAGES
  FEEL_GOOD_FRIDAY: '/feel-good-friday',
  BAND_LESSONS: '/band-lessons',
  CONTACTS: '/contacts',
  MATCH_FUNDING: '/match-funding',
  IMPACT_REPORT: '/impact-report',
  KEY_RESOURCES: '/key-resources', // formerly '/documents'
  ACCOUNT_SETTINGS: '/account-settings',
  COOKIE_POLICY: '/cookie-policy',

  // PDFs
  CASE_STUDIES: '/downloads/RocksteadyCaseStudies2022.pdf',
  PUPIL_PREMIUM_PDF: '/downloads/RocksteadyPupilPremium2022.pdf',
  RS_BROCHURE: '/downloads/RocksteadyBrochureLR.pdf',
  NOISE_REDUCED_LESSONS: '/downloads/Low_Noise_Lesson_1_1_.pdf',

  // CAMPAIGNS
  CAMPAIGN: '/campaigns/:uid',

  RESOURCE_HUB: '/resource-hub',
  RESOURCE_HUB_VIDEO: '/resource-hub/:slug',
  RESOURCE_HUB_SIGN_UP: '/resource-hub/sign-up',
  RESOURCE_HUB_SIGN_UP_CONFIRMATION: '/resource-hub/sign-up-confirmation',
  RESOURCE_HUB_LOGIN: '/resource-hub/login'
});

export default paths;
